import instance from './request'

//获取检查工程列表
export function getInspectListList(dataObj) {
  return instance({
    url: 'quality/report/inspectList',
    method: 'POST',
    params: dataObj
  })
}

//获取不良症状列表
export function getBadnessList(dataObj) {
  return instance({
    url: 'quality/report/badnessList',
    method: 'POST',
    params: dataObj
  })
}

//批量提交不良报表
export function submitBadnessList(dataObj) {
	return instance({
		url: 'quality/report/addReport1',
		method: 'POST',
		data: dataObj
  })
}

// 查询已经提交的不良报表
export function selBadnessList(dataObj) {
	return instance({
		url: 'quality/report/badnessReport1',
		method: 'POST',
		params: dataObj
  })
}
