<template>
	<div class="badness-sheet">
		<div class="title">
			<span class="icon">
				<i :class="menuIcon"></i>
			</span>
			<span class="txt">{{menuName}}</span>
		</div>
		<div class="btn">
				
			<el-button @click="hiddenMenuClick()" type="success">
				{{ !hidden ? txt1 : txt2 }}
			</el-button>
		</div>
		
		<el-tabs v-model="activeName" stretch @tab-click="handleClick">
		    <el-tab-pane label="不良报表录入" name="first">
				<div style="display: flex; height: 3.75rem; align-items: center; padding: 0 0.625rem;">
					<el-button type="primary" size="medium" style="margin-right: 1.25rem;" @click="initTableDatas">新 增</el-button>
				</div>
				<el-scrollbar style="height: calc(100% - 140px); width: 100%;">
					<el-row style="width: 100%;">
						<el-table :data="tabledatas" border height="1500" style="width: 100%">
							<el-table-column type="index" align="center" min-width="50"></el-table-column>
							
							<el-table-column v-for="(item, index) in badnessTypeList" :prop="tabledatas[currentIndex].badnessType[index].badnessId + ''" :label="tabledatas[currentIndex].badnessType[index].badnessName" align="center">
								<el-table-column min-width="60" v-if="childItem.parentId == item.badnessId" v-for="(childItem, childIndex) in item.childrenList" :key="tabledatas[currentIndex].badnessType[index].childrenList[childIndex].badnessId" :prop="tabledatas[currentIndex].badnessType[index].childrenList[childIndex].badnessId + ''" :label="tabledatas[currentIndex].badnessType[index].childrenList[childIndex].badnessName" align="center" width="100">
									<template slot-scope="scope" >
										<!-- <el-input-number :min="0" v-model="scope.row.badnessType[index].childrenList[childIndex].badnessNum" style="width: 100%;"></el-input-number> -->
										<div class="badnessNums">
											<div class="decrease" @click="scope.row.badnessType[index].childrenList[childIndex].badnessNum == 0 ? scope.row.badnessType[index].childrenList[childIndex].badnessNum = 0 : scope.row.badnessType[index].childrenList[childIndex].badnessNum --">-</div>
											<div class="number"><el-input v-model="scope.row.badnessType[index].childrenList[childIndex].badnessNum" style="width: 100%; text-align: center;"></el-input></div>
											<div class="increase" @click="scope.row.badnessType[index].childrenList[childIndex].badnessNum ++ ">+</div>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							
							</el-table-column>
							<el-table-column label="操作" align="center" min-width="60">
								<template slot-scope="scope">
									<el-button type="primary" @click="saveBtn(scope.row, 0)">保 存</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-row>
				</el-scrollbar>
			</el-tab-pane>
		    <el-tab-pane label="不良报表查询" name="second">
				<el-scrollbar style="height: calc(100% - 140px); width: 100%;">
					<el-row style="width: 100%;">
						<el-table :data="savedTableDatas" border height="1500" style="width: 100%">
							<el-table-column type="index" align="center" min-width="50"></el-table-column>
							<el-table-column v-for="(item, index) in saveBadnessTypeList" :prop="item.badnessId + ''" :label="item.badnessName" align="center">
								<el-table-column min-width="60" v-if="childItem.parentId == item.badnessId" v-for="(childItem, childIndex) in item.childrenList" :key="childItem.badnessId + ''" :prop="childItem.badnessId + ''" :label="childItem.badnessName" align="center">
									<template slot-scope="scope" >
										
										<div v-for="num in scope.row.badnessNums" v-if="childItem.badnessId == num.badnessId">
											<!-- <el-input-number :min="0" v-model="num.val" clearable style="width: 100%;"></el-input-number> -->
											<div class="badnessNums">
												<div class="decrease" @click="num.val == 0 ? num.val = 0 : num.val --">-</div>
												<div class="number"><el-input v-model="num.val" style="width: 100%; text-align: center;"></el-input></div>
												<div class="increase" @click="num.val ++ ">+</div>
											</div>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
							
							<el-table-column label="操作" align="center" min-width="60">
								<template slot-scope="scope">
									<el-button type="success" @click="submitBtn(scope.row)">提 交</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-row>
				</el-scrollbar>
			</el-tab-pane>
		</el-tabs>
		
	</div>
</template>

<script>
	import {
		getInspectListList,
		getBadnessList,
		submitBadnessList,
		selBadnessList
	} from 'network/badnessSheet'
	export default {
		name: "badnessSheet",
		data() {
			return {
				isShow: true,
				txt1: "隐藏菜单栏",
				txt2: "显示菜单栏",
				hidden: false,
				// 选项卡默认显示的模块
				activeName: "first",
				// 不良报表数据
				tabledatas: [],
				currentIndex: 0,
				// 已保存的不良报表数据
				savedTableDatas: [],
				checkId: "",
				inspectWorksList: [],
				// 不良分类列表
				badnessTypeList: [],
				badnessParentInfo: [],
				saveBadnessTypeList: [],
				// 不良症状
				badnessItemList: [],
				// 检查数量
				checkNum: 0,
			}
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		created() {
			// 获取不良项
			this.getBadnessList1();
			// 获取检查工程
			this.getInspectListList1();
			this.selBadnessList1();
		},
		methods: {
			handleClick(tab, event) {
				if(tab.name == "second") {
					this.selBadnessList1();
				}
			},
			hiddenMenuClick() {
				if(!this.hidden) {
					this.$bus.$emit("hiddenMenu");
					this.hidden = true;
				}else {
					this.$bus.$emit("showMenu");
					this.hidden = false;
				}
				
			},
			
			selectChange(value) {
				this.checkId = value;
			},
			
			// 获取检查工程列表
			getInspectListList1() {
				let obj = {
					eqId: sessionStorage.getItem("eqId")
				}
				getInspectListList(obj).then(res => {
					//console.log(res.data.data);
					if (res.data.code == 0) {
						this.inspectWorksList = res.data.data;
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			//获取不良报表录入的数据
			getBadnessList1() {
				let obj = {
					eqId: sessionStorage.getItem("eqId"),
					stationId: sessionStorage.getItem("stationId")
				}
				getBadnessList(obj).then(res => {
					if (res.data.code == 0) {
						var tableData = res.data.data;
						// 获取不良分类
						this.badnessTypeList = tableData[0]["parInfoList"];
						// 获取不良分类对应的子类
						this.badnessItemList = tableData[0]["deptList"];
						// 将不良分类对应的子类与不良分类关联起来
						// this.badnessTypeList = this.badnessParentInfo;
						this.badnessTypeList.forEach(item => {
							item["childrenList"] = [];
							this.badnessItemList.forEach(childItem => {
								if(item.badnessId == childItem.parentId) {
									item["childrenList"].push(childItem);
								}
							})
						})
						var badnessType = this.badnessTypeList;
						var checkNum = this.checkNum;
						var remark = this.remark;
						var obj = {};
						obj.inspectWorksList = this.inspectWorksList;
						obj.inspectWorksId = "";
						obj.badnessType = badnessType;
						obj.checkNum = checkNum;
						obj.remark = remark;
						this.tabledatas.push(obj);
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			
			initTableDatas() {
				this.currentIndex ++;
				this.getBadnessList1();
			},
			// 保存按钮点击事件  保存不良报表
			saveBtn(children, type) {
				// console.log(children);
				var data = children;
				var badnessIdArr = [];
				var badnessNumArr = [];
				data.badnessType.forEach(item => {
					// console.log(item.childrenList);
					item.childrenList.forEach(children => {
						// console.log(children.badnessId)
						badnessIdArr.push(children.badnessId);
						badnessNumArr.push(parseInt(children.badnessNum));
					})
				})
				// console.log(badnessIdArr);
				// console.log(badnessNumArr);
				
				let obj = {
					eqId: sessionStorage.getItem("eqId"),
					inspectWorksId: 5,
					inspectNum: data.checkNum,
					remark: data.remark,
					badnessIdArr: badnessIdArr,
					badnessNumArr: badnessNumArr,
					type: type,
					stationId: sessionStorage.getItem("stationId"),
					createBy: sessionStorage.getItem("userName")
				}
				let dataobj = [];
				dataobj.push(obj)
				submitBadnessList(dataobj).then(res => {
					if(res.data.code !== 0) {
						return this.$message.error(res.data.msg);
						// 获取保存的不良数据
						this.selBadnessList1();
						
					}else {
						this.$message.success(res.data.msg);
					}
				})
			},
			
			// 提交不良报表
			submitBtn(row) {
				var badnessIdArr = row.badnessNums.map(item => {
					return item.badnessId
				});
				var badnessNumsArr = row.badnessNums.map(item => {
					return item.val
				})
				var createBy = window.sessionStorage.getItem("userName");
				
				let obj = [{
					eqId: sessionStorage.getItem("eqId"),
					inspectWorksId: 5,
					inspectNum: row.inspectNum,
					type: 1,
					badnessIdArr: badnessIdArr,
					badnessNumArr: badnessNumsArr,
					stationId: sessionStorage.getItem("stationId"),
					remark: "",
					createBy: createBy,
					lineIdentifying: row.lineIdentifying
				}]
				// console.log(obj)
				submitBadnessList(obj).then(res => {
					// console.log(res.data.data);
					if (res.data.code == 0) {
						this.$message.success(res.data.msg);
						this.selBadnessList1();
					} else {
						this.$message.error(res.data.msg)
					}
				})
				
			},
			
			// 查询不良报表
			selBadnessList1() {
				var badnessParents = [];
				var badnessChildren = [];
				var savedTableDatas = [];
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				selBadnessList(obj).then(res => {
					savedTableDatas = res.data.data;
					if (res.data.code == 0) {
						let obj = {
							eqId: sessionStorage.getItem("eqId"),
							stationId: sessionStorage.getItem("stationId")
						}
						getBadnessList(obj).then(res => {
							if(res.data.code == 0) {
								badnessParents = res.data.data[0].parInfoList;
								badnessChildren = res.data.data[0].deptList;
								badnessParents.forEach(item => {
									item["childrenList"] = [];
									badnessChildren.forEach(childItem => {
										if(item.badnessId == childItem.parentId) {
											item["childrenList"].push(childItem);
										}
									})
								})
								this.saveBadnessTypeList = badnessParents;
								savedTableDatas.forEach(item => {
									item["badnessTypeList"] = [];
									item["badnessTypeList"] = badnessParents;
								})
								savedTableDatas.forEach(item => {
									// item.badnessNums.forEach(num => {
										
									// })
									item.badnessTypeList.forEach(parent => {
										parent.childrenList.forEach(children => {
											item.badnessNums.forEach(num => {
												// console.log(num.val)
												if(children.badnessId == num.badnessId) {
													children.badnessNum = num.val;
												}
											})
										})
									})
								})
								this.savedTableDatas = savedTableDatas;
							}
						})
					}
				})
			}
		}
	}
</script>
<style lang="scss">
	.el-table {
		font-size: 1.25rem !important;
		color: #606266 !important;
	}
	/*表头样式*/
	.el-table th {
		background: rgba(245,247,247,1) !important;
		font-size: 1.125rem !important;
		font-family: PingFang SC !important;
		font-weight: 500 !important;
		color: rgba(58,132,171,1) !important;
	}
	.el-table .cell {
		line-height: 2rem;
	}
	.el-tabs__item {
		font-size: 1.375rem;
		margin: 0.625rem 0;
		overflow: scroll;
	}

	.el-tabs__item.is-active {
		color: rgba(58, 132, 171, 1);
	}

	.el-tabs__item:hover {
		color: rgba(58, 132, 171, 1);
	}

	.el-tabs__active-bar {
		background: rgba(58, 132, 171, 1);
	}

	.form-item-box > label {
		font-size: 1.125rem !important;
	}

	.el-form-item__label {
		// background-color: gray;	
		color: #606266;
		font-size: 1.375rem !important;
		width: 100%;
	}
	.el-input {
		font-size: 1.125rem;
	}
	.el-input__inner {
		line-height: 3rem;
		height: 3rem;
	}
	.badnessNums {
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
		border: 0.05rem solid #DCDFE6;
		border-radius: 0.625rem;
	}
	.decrease, .increase {
		width: 100%;
		height: 2rem;
		font-size: 1.375rem !important;
		
	}
</style>
<style lang="scss" scoped>
	.badness-sheet {
		width: 100%;
		height: 100%;
		// overflow: hidden;
		overflow: scroll;
		position: relative;
		border-radius: 0.625rem;
		box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, .1);

		.fullScreen {
			position: absolute;
			top: 0.625rem;
			right: 0.625rem;
		}
		
		.title {
			width: 100%;
			display: flex;
			padding: 0.625rem;

			.icon {
				display: inline-block;
				width: 2.5rem;
				height: 2.5rem;
				text-align: center;
				line-height: 2.5rem;
				border-radius: 0.625rem;
				margin-right: 1.25rem;
				background: linear-gradient(0deg, rgba(41, 187, 192, 1) 0%, rgba(73, 208, 206, 1) 100%);

				i {
					font-size: 1.375rem;
					font-weight: 500;
					color: white;
				}
			}
			
			.txt {
				height: 2.5rem;
				font-size: 1.625rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(58, 132, 171, 1);
				line-height: 2.5rem;
				letter-spacing: 3px;
			}
		}	
		.btn {
			position: absolute;
			right: 0.625rem;
			top: 0.625rem;
		}
	}
</style>
